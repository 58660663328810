import { ServiceStationStorageService } from 'services/service-station-storage-service';
import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { StorageHistoryService } from 'services/storage-history-service';
import { StorageInvoiceService } from 'services/storage-invoice-service';
import { ToastService } from 'services/toast-service';
import { NetService } from 'services/net-service';
import moment from 'moment';
import { ErrorService } from 'services/error-service';

@autoinject
export class StorageSetTimeDialog {
  private storageHistory: Models.StorageHistory;
  private serviceStationStorages: Models.ServiceStationStorage[];

  private item: Models.StorageViewPipelineItem;

  timeSpentInDisabled: boolean = false;
  timeSpentOutDisabled: boolean = false;

  constructor(
    private dialogController: DialogController,
    private storageHistoryService: StorageHistoryService,
    private serviceStationStorageService: ServiceStationStorageService,
    private toastService: ToastService,
    private netService: NetService,
    private errorService: ErrorService
  ) {}

  activate(params) {
    this.item = JSON.parse(JSON.stringify(params.item));
    void this.getServiceStationStorages();
    void this.getItem();
  }

  async getItem() {
    const data = await this.netService.getStorageViewItemDetails(this.item.Id);
    this.storageHistory = {
      ...data,
      TimeStartIn: data.TimeStartIn ? moment(data.TimeStartIn).format('HH:mm') : null,
      TimeEndIn: data.TimeEndIn ? moment(data.TimeEndIn).format('HH:mm') : null,
      TimeStartOut: data.TimeStartOut ? moment(data.TimeStartOut).format('HH:mm') : null,
      TimeEndOut: data.TimeEndOut ? moment(data.TimeEndOut).format('HH:mm') : null,
    };

    this.timeStartChange();
    this.timeEndChange();
  }

  private async getServiceStationStorages() {
    const storages = await this.serviceStationStorageService.getAllCached();
    this.serviceStationStorages = storages.filter((it) => !it.IsDeleted);
  }

  protected async save() {
    try {
      const data: any = {
        Title: this.storageHistory.Title,
        Comment: this.storageHistory.Comment,
        In: this.storageHistory.In,
        Out: this.storageHistory.Out,
        ServiceStationStorageId: this.storageHistory.ServiceStationStorageId,
        TimeStartIn: this.storageHistory.TimeStartIn,
        TimeEndIn: this.storageHistory.TimeEndIn,
        TimeSpentIn: moment.duration(this.storageHistory.TimeSpentIn).asMinutes(),
        TimeStartOut: this.storageHistory.TimeStartOut,
        TimeEndOut: this.storageHistory.TimeEndOut,
        TimeSpentOut: moment.duration(this.storageHistory.TimeSpentOut).asMinutes(),
      };

      await this.netService.updateStorageViewDetails(this.item.Id, data);
      this.toastService.showSuccess('storages.saved');
      void this.dialogController.ok();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  timeStartChange() {
    if (this.storageHistory.TimeStartIn || this.storageHistory.TimeEndIn) {
      this.timeSpentInDisabled = true;

      const start = moment(this.storageHistory.TimeStartIn, 'HH:mm');
      const end = moment(this.storageHistory.TimeEndIn, 'HH:mm');
      const hours = end.diff(start, 'hours');
      const mins = moment.utc(end.diff(start)).format('mm');
      const differenceString = moment.utc(end.diff(start)).format('HH:mm');

      if (hours < 0 || hours + +mins <= 0) {
        this.storageHistory.TimeSpentIn = null;
      } else {
        this.storageHistory.TimeSpentIn = differenceString as any;
      }
    } else {
      this.timeSpentInDisabled = false;
    }
  }

  timeEndChange() {
    if (this.storageHistory.TimeStartOut || this.storageHistory.TimeEndOut) {
      this.timeSpentOutDisabled = true;

      const start = moment(this.storageHistory.TimeStartOut, 'HH:mm');
      const end = moment(this.storageHistory.TimeEndOut, 'HH:mm');
      const hours = end.diff(start, 'hours');
      const mins = moment.utc(end.diff(start)).format('mm');
      const differenceString = moment.utc(end.diff(start)).format('HH:mm');

      if (hours < 0 || hours + +mins <= 0) {
        this.storageHistory.TimeSpentOut = null;
      } else {
        this.storageHistory.TimeSpentOut = differenceString as any;
      }
    } else {
      this.timeSpentOutDisabled = false;
    }
  }
}
