import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { StorageHistoryService } from 'services/storage-history-service';
import { StorageInvoiceService } from 'services/storage-invoice-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class StorageInvoiceDialog {
  private selectedItems: any[];
  private progress: boolean = false;
  private storageInvoice: Models.StorageInvoice;
  private invoicedDate: Date = new Date();

  constructor(
    private dialogController: DialogController,
    private storageInvoiceService: StorageInvoiceService,
    private storageHistoryService: StorageHistoryService,
    private toastService: ToastService
  ) {}

  activate(params) {
    this.storageInvoice = new Models.StorageInvoice();
    this.storageInvoice.CustomerId = params.selectedItems[0].CustomerId;
    this.storageInvoice.ServiceStationId = params.serviceStationId;

    this.selectedItems = params.selectedItems;
  }

  private async createInvoice(markAsInvoiced: boolean = false) {
    this.progress = true;

    if (markAsInvoiced) {
      this.storageInvoice.Invoiced = new Date();
    }

    try {
      const invoice = await this.storageInvoiceService.post(this.storageInvoice);

      const itemIds = this.selectedItems.map((it) => it.Id);
      await this.storageHistoryService.invoiceItems(itemIds, invoice.Id, this.invoicedDate);

      this.toastService.showSuccess('storages.invoiceItemsSuccess');
      this.dialogController.ok();
    } catch (error) {
      this.progress = false;
      throw error;
    }
  }
}
