import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, BindingEngine } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { activationStrategy, Router } from 'aurelia-router';
import { Models } from 'models/core';
import { UserModels } from 'models/UserModels';
import * as moment from 'moment';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { ListSettingsService } from 'services/list-settings-service';
import { NetService } from 'services/net-service';
import { NetStatusService } from 'services/net-status-service';
import { NetTypeService } from 'services/net-type-service';
import { ProducerService } from 'services/producer-service';
import { ServiceStationService } from 'services/service-station-service';
import { StorageInvoiceService } from 'services/storage-invoice-service';
import { ToastService } from 'services/toast-service';
import { UserService } from 'services/user-service';
import { SpecialProductTypeService } from './../../services/special-product-type-service';
import { StorageInvoiceDetailsDialog } from './storage-invoice-details-dialog';

const SELECTED_STORAGE_INVOICE_COLUMNS = 'SELECTED_STORAGE_INVOICE_COLUMNS';

@autoinject
export class StorageInvoiceList {
  private tableData: Array<any>;
  private ready: boolean;
  private params: any;
  private userData: UserModels.User;
  private totalItems: number = 15;
  private netTypes: Array<Models.NetType>;
  private specialProductTypes: Array<Models.SpecialProductType>;

  @bindable private currentPage: number = 1;
  private customerid;
  private context: string;

  private selectedFields: Array<any>;
  private STORAGE_INVOICE_FILTERS_KEY = 'STORAGE_INVOICE_FILTERS_KEY';
  private showSearchDefault: boolean = false;

  private fields = null;

  private tableViewType: number = 1;
  private tableViewVerticalLines: boolean = false;
  private tableMargin: number = 2;
  private pageSize: number = 25;
  private searchTextSubscription: Subscription;

  private isSpecialProductList: boolean = false;

  private showNetFilters: boolean = true;
  private netFiltersList: Array<any> = [];
  @bindable private filtersVisible: any = null;
  @bindable private hideNetFilters: boolean = false;

  private netTypeCheckboxes = [];
  private netTypeCheckboxesObserver: any;

  private specialProductTypeCheckboxes = [];
  private specialProductTypeCheckboxesObserver: any;

  private quickEditFieldOpen: string = null;
  private quickEditFields: object = {};

  // FILTERS
  private storageInvoiceFilters: any;
  private filterGetFiltersQuery: any;
  private filterToggleVisible: any;
  private filterClearAll: any;

  private serviceStation: Models.ServiceStation;
  private serviceStationId: number;
  private serviceStations: Models.ServiceStation[];

  constructor(
    private netService: NetService,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private netStatusService: NetStatusService,
    private userService: UserService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private producerService: ProducerService,
    private bindingEngine: BindingEngine,
    private i18n: I18N,
    private listSettingsService: ListSettingsService,
    private netTypeService: NetTypeService,
    private specialProductTypeService: SpecialProductTypeService,
    private storageInvoiceService: StorageInvoiceService,
    private dialogService: DialogService,
    private serviceStationService: ServiceStationService,
    private toastService: ToastService
  ) {}

  private getFilterKey() {
    return this.STORAGE_INVOICE_FILTERS_KEY;
  }

  private activate(params) {
    this.params = params;
  }

  private attached(params) {
    this.ready = false;

    if (!this.params && params) {
      this.params = params;
    } else if (!params && this.params) {
      params = this.params;
    }
    if (!this.params) {
      this.params = {};
      params = {};
    }

    Promise.all([
      this.userService.getCurrentUser(),
      this.listSettingsService.getDefaultPageSize(),
      this.serviceStationService.getAllCached(),
    ])
      .then((responses) => {
        this.userData = responses[0];

        this.fields = this.getFields();

        // get selected values from localstorage
        const selectedFields: Array<string> = JSON.parse(localStorage.getItem(SELECTED_STORAGE_INVOICE_COLUMNS));

        if (selectedFields) {
          this.fields.forEach((field) => {
            if (field.visible) {
              field.selected = false;
            }
          });

          selectedFields.forEach((selectedField) => {
            const field = this.fields.find((x) => x.field === selectedField);
            if (field) {
              field.selected = true;
            } else {
              field.selected = false;
            }
          });
        }

        // list-settings
        this.pageSize = responses[1];

        // Servicestations
        this.serviceStationId = this.userData.ServiceStationId;
        this.serviceStations = responses[2].filter((x) => !x.IsDeleted).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        if (!this.serviceStationId) {
          this.changeServiceStation(this.serviceStations[0]);
        } else {
          let serviceStation = this.serviceStations.find((x) => x.Id === this.serviceStationId);
          this.changeServiceStation(serviceStation);
        }

        this.getStorageInvoices();
      })
      .catch((err) => this.errorService.handleError(err));

    // Close all quickedit-fields on Esc
    document.addEventListener('keyup', (event) => {
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        this.quickEditFieldOpen = null;
      }
    });
  }

  private determineActivationStrategy() {
    return activationStrategy.replace;
  }

  private buildQueryString(obj) {
    const str = [];
    for (const p in obj) {
      if (!p.startsWith('_') && obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  private pushState() {
    const baseUrl = '#' + this.router.baseUrl + '/storage-invoices';
    const stateObj = {
      currentPage: this.currentPage,
    };
    const queryString = this.buildQueryString(stateObj);
    history.pushState(stateObj, 'storage-invoices', baseUrl + '?' + queryString);
  }

  private sortTable(field) {
    if (!this.storageInvoiceFilters) {
      return;
    }

    if (this.storageInvoiceFilters.orderBy === field.field) {
      this.storageInvoiceFilters.orderByDirection =
        this.storageInvoiceFilters.orderByDirection === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.storageInvoiceFilters.orderBy = field.field;
      this.storageInvoiceFilters.orderByDirection = 'DESC';
    }

    this.pushState();
    this.saveFilters();
    this.getStorageInvoices();
  }

  private currentPageChanged(newValue, oldValue) {
    if (!this.storageInvoiceFilters) {
      // cannot change page, netFilters not defined
      return;
    }

    if (parseInt(newValue, 10) === parseInt(oldValue, 10)) {
      return;
    }

    this.pushState();
    this.storageInvoiceFilters.skip = (+newValue - 1) * (this.storageInvoiceFilters.top ?? this.pageSize);
    this.getStorageInvoices();
  }

  private getFields() {
    return [
      { field: 'Id', title: 'general.id', selected: true, disabled: false },
      { field: 'CustomerName', title: 'general.customer', selected: true, disabled: false },
      { field: 'Invoiced', title: 'storages.invoiced', selected: true, isDate: true, disabled: false, quickEdit: true },
      { field: 'ItemsCount', title: 'storages.itemsCount', selected: true, disabled: false },
      { field: 'DaysInStorageTotal', title: 'storages.daysInStorage', selected: true, disabled: false },
      { field: 'TimeSpentTotalString', title: 'storages.timeSpent', selected: true, disabled: false },
    ];
  }

  private quickEditSetup(tableData: Array<Models.Service>, fields) {
    tableData.forEach((row: any) => {
      this.quickEditFields[row.Id] = [];
      fields.forEach(({ field, quickEdit }) => {
        if (quickEdit) {
          // Other fields
          this.quickEditFields[row.Id].push(field);
        }
      });
    });
  }

  private isQuickEditField(rowId, fieldName) {
    return this.quickEditFields[rowId].includes(fieldName);
  }

  // Close datepicker on clicks outside quickedits
  public handleUpperTableClicks(event) {
    if (
      event?.toElement?.classList?.contains('field-quickedit') ||
      event?.toElement?.tagName === 'INPUT' ||
      event?.toElement?.tagName === 'DATEPICKER'
    ) {
      return event;
    } else {
      this.quickEditFieldOpen = null;
    }
    return event;
  }

  private quickEditField(rowId, field, event) {
    if (this.isQuickEditField(rowId, field)) {
      if (event?.srcElement?.classList?.contains('field-quickedit')) {
        if (this.quickEditFieldOpen === `${rowId}_${field}`) {
          this.quickEditFieldOpen = null;
        } else {
          this.quickEditFieldOpen = `${rowId}_${field}`;
        }
      }
    }
  }

  private quickEditInputOnFocus(rowId, field) {
    this.quickEditFieldOpen = `${rowId}_${field}`;
  }

  private quickEditFieldDateChange({ detail }, rowId, { field }) {
    let doUpdate = false;
    const rowIndex = this.tableData.findIndex((x: Models.Service) => x.Id === rowId);

    if (detail && !this.tableData[rowIndex][field]) {
      // set new value
      doUpdate = true;
    } else if (!detail && this.tableData[rowIndex][field]) {
      // clear value
      doUpdate = true;
    } else if (detail && !moment.utc(detail).isSame(moment.utc(this.tableData[rowIndex][field]))) {
      // update
      doUpdate = true;
    }

    if (doUpdate) {
      const data: any = {
        Invoiced: detail,
      };
      this.storageInvoiceService
        .put(data, this.tableData[rowIndex].Id)
        .then((res) => {
          this.tableData[rowIndex][field] = res[field];
          this.toastService.showSuccess('general.saved');
          this.quickEditFieldOpen = null;
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  private async clearFilters() {
    await this.filterClearAll();
  }

  private async getStorageInvoices() {
    if (this.fields == null) {
      this.fields = this.getFields();
    }

    this.selectedFields = this.fields.filter((x) => x.selected && (x.visible === undefined || x.visible === true));

    // Needs to be inside a timeout because query-function
    // is binded in from net-filters
    setTimeout(async () => {
      let filtersQuery: any = await this.filterGetFiltersQuery(this.fields);

      setTimeout(() => {
        if (!this.searchTextSubscription) {
          this.searchTextSubscription = this.bindingEngine
            .propertyObserver(this.storageInvoiceFilters, 'searchText')
            .subscribe((newValue, oldValue) => {
              this.saveFilters();
              this.getStorageInvoices();
            });
        }

        filtersQuery._export = false;
        filtersQuery._select = this.fields
          .filter((x) => x.selected)
          .map((x) => x.field)
          .join(',');

        filtersQuery.top = this.pageSize;
        filtersQuery.skip = this.storageInvoiceFilters.skip;
        filtersQuery.orderBy = this.storageInvoiceFilters.orderBy;
        filtersQuery.orderByDirection = this.storageInvoiceFilters.orderByDirection;
        filtersQuery.serviceStationId = this.serviceStationId;

        this.setAdditionalFilters(filtersQuery);

        this.storageInvoiceService
          .getItems(filtersQuery)
          .then((res) => {
            res.json().then((data) => {
              this.tableData = data;
              this.quickEditSetup(this.tableData, this.fields);
              this.totalItems = res.headers.get('x-total-count');
              this.ready = true;
            });
          })
          .catch((err) => {
            this.ready = true;
            this.errorService.handleError(err);
          });
      });
    }, 150);
  }

  private setAdditionalFilters(netFiltersQuery) {
    if (!!this.customerid) {
      netFiltersQuery.customerId = this.customerid;
    }
    if (!!this.storageInvoiceFilters.searchText) {
      netFiltersQuery.searchText = this.storageInvoiceFilters.searchText;
    }
  }

  private saveFilters() {
    const data: any = {};

    for (const [key, value] of Object.entries(this.storageInvoiceFilters)) {
      const filter = this.storageInvoiceFilters[key];
      if (!filter || typeof filter === 'undefined') {
        continue;
      } else if (typeof filter === 'string' || typeof filter === 'number') {
        data[key] = filter;
      } else {
        data[key] = {
          values: filter.values,
          visible: filter._visible ?? false,
        };
      }
    }

    data.orderBy = this.storageInvoiceFilters.orderBy;
    data.orderByDirection = this.storageInvoiceFilters.orderByDirection;
    data.top = this.storageInvoiceFilters.top ?? this.pageSize;
    data.skip = this.storageInvoiceFilters.skip;

    localStorage.setItem(this.getFilterKey(), JSON.stringify(data));
  }

  private updateSelectList(event, field) {
    const selectedFields = this.fields.filter((x) => x.selected).map((x) => x.field);
    localStorage.setItem(
      SELECTED_STORAGE_INVOICE_COLUMNS + (this.context ? '_' + this.context : ''),
      JSON.stringify(selectedFields)
    );
    this.getStorageInvoices();
  }

  private rowClick(row: any, event) {
    // Don't do anything if clicking on a quickedit-field
    if (
      event?.srcElement?.classList?.contains('field-quickedit') ||
      event?.srcElement?.tagName === 'INPUT' ||
      event?.srcElement?.tagName === 'DATEPICKER'
    ) {
      return;
    }

    // Close quickEdit-field
    this.quickEditFieldOpen = null;

    this.dialogService
      .open({
        viewModel: StorageInvoiceDetailsDialog,
        model: { storageInvoice: row },
        lock: false,
        keyboard: true,
      })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          this.getStorageInvoices();
        }
      });
    return event;
  }

  changeServiceStation(serviceStation) {
    this.serviceStationId = serviceStation.Id;
    this.serviceStation = serviceStation;
    this.currentPage = 1;
    this.eventAggregator.publish('dropdownClose', 1);
    if (this.tableData) {
      this.getStorageInvoices();
    }
  }
}
