import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { Models } from "models/core";
import { ErrorService } from "services/error-service";
import { StorageHistoryService } from "services/storage-history-service";
import { StorageInvoiceService } from "services/storage-invoice-service";
import { ToastService } from "services/toast-service";

@autoinject
export class StorageInvoiceDetailsDialog {
  private storageHistoryItems: Models.StorageHistory[];
  private storageInvoice: Models.StorageInvoice;
  private progress: boolean = false;

  constructor(
    private dialogController: DialogController,
    private storageInvoiceService: StorageInvoiceService,
    private storageHistoryService: StorageHistoryService,
    private toastService: ToastService,
    private errorService: ErrorService
  ) {}

  activate(params) {
    this.storageInvoice = JSON.parse(JSON.stringify(params.storageInvoice));
    this.getStorageHistoryItems();
  }
  
  async getStorageHistoryItems() {
    let params: any = {
      serviceStationId: this.storageInvoice.ServiceStationId,
      storageInvoiceId: this.storageInvoice.Id,
    }

    this.storageHistoryService.getItems(params)
    .then(res => {
      res.json().then(data => {
        this.storageHistoryItems = data;
      })
    });
  }

  async save() {
    this.progress = true;

    try {
      const data: any = {
        Invoiced: this.storageInvoice.Invoiced,
      }
      await this.storageInvoiceService.put(data, this.storageInvoice.Id);
      this.toastService.showSuccess('general.saved');
      this.dialogController.ok();
    } catch (error) {
      this.errorService.handleError(error);
      this.progress = false;
    }

  }

  async undoInvoice() {
    this.progress = true;
    try {
      await this.storageInvoiceService.undo(this.storageInvoice.Id);
      this.toastService.showSuccess('storages.invoiceUndoSuccess');
      this.dialogController.ok();
    } catch (error) {
      this.errorService.handleError(error);
      this.progress = false;
    }
  }

}
